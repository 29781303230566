/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React, { useContext, useEffect, useState } from "react";
import WETTable from "../../components/WETTable-shared";
import AppContext from "../../components/AppContext-shared";
import Spinner from "../../components/Spinner-shared";

import { isExternal, domId} from "../../utils/miscUtils-shared";
import { FormattedMessage } from "react-intl";

// ! TODO: need different columns here based on internal/external

const Search = () => {
  const { districts, offices, messages, provinces, updateActiveOffice } = useContext(AppContext);

	const [showSpinner, setShowSpinner] = useState(true);
  const [officeList, setOfficeList] = useState([]); //list of offices for WET table

  const customHeader = [
    'ID', //hidden
    messages['label.name'], 
    <> 
      {/* Airport */}
      <span className="display-full">{messages['label.airport']}</span> 
      <span className="display-short" title={messages['label.office.airportCode']}>
        <i className="fa-solid fa-plane-up fa-lg"></i>
      </span>
    </>, 
    <> 
      {/* Province */}
      <span className="display-full">{isExternal() ? messages['label.province'] : messages['label.district']}</span> 
      <span className="display-short" title={messages['label.province']}>
        {isExternal() ? messages['label.province.short'] : messages['label.district.short']}
      </span>
    </>, 
  ]

  //Create list used by WET table
  const createOfficeList = (officeArray) => {
    let officeList = []

    //COLUMNS
    //* any changes to this you'll have to re-factor the 'data-column' to ensure it's filtering on the correct column
    officeArray.forEach((office) => { isExternal() ?
        //EXTERNAL columns [number (hidden), name/alias, airport, province]
        officeList.push([
          office.number, //number used for the <Link dataId>
          <>
            {office.name} 
            <div className="geo-alias">{office.alias}</div>
          </>,
          office.airportCode,
          <>
            <span className="display-full">{provinces.get(office.province)?.name}</span>
            <span className="display-short" title={provinces.get(office.province)?.name}>{provinces.get(office.province)?.code}</span>
          </>,
        ])
        :
        //INTERNAL columns [number (hidden), name/alias, airport, district]
        officeList.push([
          office.number, //number used for the <Link dataId>
          <>
            {office.name} 
            {/* FOR ADMINS - office.inactiveStatus presence means office is not active: -1 => before effective date, -1 => after expiry date. Prop not included if office is active */}
            {!!office.inactiveStatus && 
              <span 
                title={messages[office.inactiveStatus > 0 ? 'label.expired' : 'label.pending']} 
                className={`fa fa-exclamation-circle ${office.inactiveStatus > 0 ? 'expired-flag' : 'pending-flag'}`}
              />
            }
            <div className="geo-alias">{office.alias}</div>
            {/* Hidden text - make it easier to text search for all inactive, expired or pending offices */}
            {!!office.inactiveStatus && 
              <span className="hide">
                {messages['label.inactive']} {messages[office.inactiveStatus > 0 ? 'label.expired' : 'label.pending']}
              </span>
            }
          </>,
          office.airportCode,
          districts.get(office.districtNumber)?.name
        ])
    });
    setOfficeList(officeList)
  }

  //Initial load code
  useEffect(() => {
    //TODO: still necessary? not sure if this is the best way to do it since this prevents spinner showing up in case page dynamically reloads
    if(!domId("searchOffices").classList.contains('wb-tables-inited')) {
      setShowSpinner(true);
    }

    createOfficeList(Array.from(offices.values()));

    //TODO: block the table display until this is done so you don't ever see the hidden column
    //TODO: Hide the ID column used to set the link (actually just make this the last hidden column to make things easier)
    
    //Need to wait until table is initialized or causes errors
    window.$('#searchOffices').on('init.dt', function () {
      window.$('#searchOffices').DataTable().column(0).visible(false); //ID column for external
      window.$('#searchOffices').DataTable().order([1, 'asc']).draw();
      const searchBar = document.querySelector('input[type="search"]');
      if(searchBar) {
        searchBar.placeholder = "Example: 'Pearson Airport' or 'YYZ'"; //put placeholder text (we don't control it, DataTables does)
        searchBar.focus();
      }
      setShowSpinner(false);
      domId('find-office')?.scrollIntoView({ behavior: 'smooth'}) //scroll page for optimal viewing
    });
  }, []);

  return (
    <div className="panel-body">
      <Spinner className="spinner" show={showSpinner}/>
      <h3><FormattedMessage id="label.search.title" /></h3>
      {
        <WETTable
          headers={customHeader}
          rows={officeList}
          addHref={{ 
            useHref: true, 
            clickFunction: updateActiveOffice,
            colForLink: 1, 
            colForData: 0,
          }}
          routeName="Office"
          tableId="searchOffices"
          rowId={0} //column to use for row's ID which we use during filtering
        ></WETTable>
      }
    </div>
  );
};

export default Search;
