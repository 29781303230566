/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React, { useContext } from "react";
import './css/ErrorOverlay-shared.css'

import AppContext from "./AppContext-shared";

function ErrorOverlay({show}) {
    const { messages } = useContext(AppContext);
    document.body.style.overflow = 'hidden';
	return (
    <div className={`error-container ${show && 'show'}`}>
        <div className={'message'}>
        <section class="alert alert-danger">
            {messages['http.500.content']}
        </section>
        </div>
        
    </div>
  );
}

export default ErrorOverlay;