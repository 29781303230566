/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import { renderToStaticMarkup} from 'react-dom/server';
import React, { useEffect, useState, useRef, useContext } from "react";
import AppContext from "./AppContext-shared";

import './css/BingMap-shared.css';

/**
 * ! NOTES
 * ! Props: expects an offices ARRAY
 * 
 *  Default view is to the whole country
 * 
 * TODO: when click on a pin, move the view to encompass the infobox
 * 
 */
const CANADA_COORDINATES = [60.7907886528436, -95.87072138507942];

const BingMap = ({zoomLevel, parentName, offices: propOffices}) => {
    const elementId = `myMap-${parentName}`

    const [loadingCheck, setLoadingCheck] = useState(false); //use to prevent actions until bing src is fully loaded
    const [bingOffices, setOffices] = useState(propOffices);

    const mapRef = useRef(null); //bing map object
    const infoboxRef = useRef(null); //bing map info box to show pin information

    const { offices, services, messages, updateActiveOffice} = useContext(AppContext); 

    let clusterLayer;

    //Update map
    function loadMapInfo() {
        const map = mapRef.current;
        let pins = [];
        let pinLocations = [];

        //Set info for pins (location, name, id)
        bingOffices?.forEach((office) => {
            if(!office?.latitude || !office?.longitude) {
                return;
            }
            const location = new window.Microsoft.Maps.Location(office.latitude, office.longitude);
            pinLocations.push(location);

            const pin = new window.Microsoft.Maps.Pushpin( location, { title: office.name } );
            pins.push(pin);

            //Use to set the infobox content when user clicks a pin in pushTest()
            pin.metadata = {
                title: office.name,
                officeId: office.number
            }

            //add click event handler to the pushpin
            window.Microsoft.Maps.Events.addHandler(pin, 'click', pinClicked);
        })

        //Set view zoom to make sure all offices in view
        if(pinLocations.length > 1) {
            const bounds = window.Microsoft.Maps.LocationRect.fromLocations(pinLocations);
            map.setView({ bounds: bounds, padding: 22 }); //padding = extra space given to bounding box (22 is minimum => to fit saskatchewan)
        }
        else { map.setView({ center: pinLocations[0], zoom: 15 }); } //single location is just centered

        // Do this if you prefer to just have regular pins, otherwise clustering layer below will work
        // map.entities.clear();
        // map.entities.push(pins);  

        //! TODO: if 2 locs have the exact same coordinates they will NEVER break apart, this is an issue (probably best way to solve is to listen to zoom change and if it's at max, disable clustering)
        //Load Clustering module - groups pins when zoomed out
        window.Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
            map.layers.clear();
            clusterLayer = new window.Microsoft.Maps.ClusterLayer(pins, {
                clusteredPinCallback: createCustomClusterPushpins,
                //callback: createPushpinList
                // gridSize: 45 //use to adjust clustering size, 45 is default
            });
            map.layers.insert(clusterLayer);
        });
    }

    function createCustomClusterPushpins(cluster) {
        //Create title for the cluster
        cluster.setOptions({
            title: 'Cluster of ' + cluster.containedPushpins.length + ' pins'
        });

        //Add handler for the cluster click event
        //window.Microsoft.Maps.Events.addHandler(cluster, 'click', clusterClicked)
    }


    function pinClicked(e) {
        if (e.target?.metadata) {
            const pin = e.target.metadata;
            const office = offices.get(+pin.officeId);
            const titleId = `bing-link-${pin.officeId}`;

            //Content of info box
            const boxContent = 
                <div>
                    <a className="bing-title" id={titleId} href="#" data-office-id={pin.officeId}>{pin.title}</a>
                    <div className="bing-service-label">{messages['label.office.services']}:</div>
                    {office.services ? 
                        <ul>
                            {office.services?.map((serviceNum) => {
                                return <li key={serviceNum}>{services.get(serviceNum)?.name}</li>
                            })}
                        </ul>
                        :
                        <div>{messages['label.none']}</div>
                    }
                </div>
            
            infoboxRef.current.setOptions({
                location: e.target.getLocation(),
                description: renderToStaticMarkup(boxContent),
                maxHeight: 400,
                maxWidth: 400,
                visible: true
            });

            //Add listener
            document.getElementById(titleId).addEventListener('click', (event) => {
                event.preventDefault();
                updateActiveOffice(event.target.dataset?.officeId);
            });
        }
    }

    //Runs after Bing scripts finish loading
    window.GetMap = function () {
        mapRef.current = new window.Microsoft.Maps.Map('#' + elementId, {
            center: new window.Microsoft.Maps.Location(...CANADA_COORDINATES), //default country view
            zoom: zoomLevel ?? 5
        });
        infoboxRef.current = new window.Microsoft.Maps.Infobox(mapRef.current.getCenter(), {
            visible: false
        });
        infoboxRef.current.setMap(mapRef.current);       

        setLoadingCheck(true);
    }

    //import external Bing Map API Script and check if map is loaded
    useEffect(() => {
        const script = document.createElement('script');

        //External script URL | call GetMap when API finishes loading
        // TODO: handle expiry/invalid key properly. It will paint a banner across the map, catch the error and handle it
        script.src = `https://www.bing.com/api/maps/mapcontrol?callback=GetMap&key=${window.env.map}`;
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.onerror = async () => { console.error('Error loading external BingMap Script') };
        
        document.head.appendChild(script);

        //clean up the script when component unmounts
        return () => { 
            document.head.removeChild(script); 
        } 
    }, []);

    // loadingCheck: display initial map when Maps API has finally finished loading
    // offices: update map whenever offices are updated
    useEffect(() => {
        if(loadingCheck) {
            loadMapInfo();        
        }
    },[loadingCheck, bingOffices])

    //Set office state when prop changes (need to separate from office useState to ensure changes are reflected correctly)
    useEffect(() => {
        setOffices(propOffices);
    }, [propOffices]);

    return (
        <div id={elementId} className="map-container">
        </div>
    )
}

export default BingMap;